import './bootstrap';
import axios from 'axios';
axios.defaults.headers.common['X-API-KEY'] = import.meta.env.VITE_API_KEY;

import { createRoot } from 'react-dom/client';
import { createInertiaApp } from '@inertiajs/react';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';

createInertiaApp({
    title: (title) => `${title}`,
    siteName: '24Shup',
    resolve: (name) => {
        const [type, page] = name.split('::');
        if(type === 'Be'){
            import('@cssbe/app.less');
            return resolvePageComponent(`./Be/${page}.jsx`, import.meta.glob('./Be/**/*.jsx'));
        }

        if(type === 'Me'){
            import('@cssme/app.less');
            return resolvePageComponent(`./Me/${page}.jsx`, import.meta.glob('./Me/**/*.jsx'));
        }        

        import('@cssfe/app.less');
        return resolvePageComponent(`./Fe/${page}.jsx`, import.meta.glob('./Fe/**/*.jsx'));
    },
    setup({ el, App, props }) {
        const root = createRoot(el);
        root.render(<App {...props} />);
    },
    progress: {
        color: '#4B5563',
    },
});